const sizeGuideByCategory = category => {
  let url = ''
  let strapi_id = -1
  let pdf = ''
  switch (category) {
    case 'Watch':
    case 'Bracelet':
      url = '/blog/how-to-measure-your-wrist-size'
      pdf = '/pdfs/watch-size-guide.pdf'
      strapi_id = 736
      break
    case 'Watch Strap':
    case 'Watch Band':
      url = '/blog/howto-find-perfect-strap-for-luxury-watch'
      pdf = '/pdfs/watchband-measuring-guide.pdf'
      strapi_id = 656
      break
    case 'Ring':
      url = '/blog/how-to-measure-your-ring-size'
      pdf = '/pdfs/gns_ring_measurement.pdf'
      strapi_id = 737
      break
    case 'Necklace':
      url = '/blog/how-to-measure-your-neck-size-for-necklace/'
      pdf = '/pdfs/Necklace-Measuring-Guide.pdf'
      strapi_id = 773
      break
    default:
      url = null
  }
  return { url, strapi_id, pdf }
}

module.exports = { sizeGuideByCategory }
