const differenceInMonths = (date2, date1) => {
  const months = (date2.getFullYear() - date1.getFullYear()) * 12
  const monthsDiff = months + date2.getMonth() - date1.getMonth()
  return monthsDiff
}

const differenceInYears = (date2, date1) => {
  return date2.getFullYear() - date1.getFullYear()
}

export const convertDateAgoFromNow = stockDate => {
  const now = new Date()
  stockDate = new Date(stockDate)
  const monthsDifference = differenceInMonths(now, stockDate)
  const yearsDifference = differenceInYears(now, stockDate)

  if (monthsDifference < 6) {
    return 'recently'
  } else if (monthsDifference < 12) {
    return 'within 12 months'
  } else if (yearsDifference < 2) {
    return 'a year ago'
  } else {
    return 'over 2 years ago'
  }
}
