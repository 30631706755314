import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const AddToCartBTN = ({ onClick, isInCart = false }) => {
  const [isAddToCart, setIsAddToCart] = useState('init')
  const handleClicked = React.useCallback(
    (e, c = false) => {
      if (isAddToCart === 'clicked' || isInCart) {
        navigate('/checkout/')
        return
      }
      setIsAddToCart('clicked')
      if (typeof onClick === 'function') onClick(e)
      if (e.target.id === 'checkout' || c === true) {
        navigate('/checkout/')
      }
    },
    [isAddToCart, onClick]
  )
  React.useEffect(() => {
    if (isInCart) {
      const t = setTimeout(() => {
        setIsAddToCart('clicked')
      }, 500)
      return () => {
        clearTimeout(t)
      }
    }
  }, [isInCart])

  return (
    <div className="col-span-2 flex flex-row rounded-md overflow-hidden">
      <button
        id="add-to-cart"
        className={
          (isAddToCart === 'clicked' ? '  w-[0rem]  ' : '  w-[4rem] ') +
          ' h-12 duration-500 ease-in-out bg-red-700 text-white group border-r '
        }
        onClick={handleClicked}
      >
        <div className=" animate-bounce size-2 bg-white w-1 h-4 relative ml-[1.6rem] mb-4 group-hover:mb-0 mt-[-1rem] group-hover:mt-[.4rem] duration-150 ease-out">
          <div className="absolute -left-1 border-b-[3px] border-l-[3px] bottom-[.1rem] size-3 -rotate-45 border-white " />
        </div>
        <svg
          role={'img'}
          aria-label={'Add To Cart'}
          viewBox="0 0 200 200"
          className={'h-full aspect-square mx-auto -translate-y-5 p-2 '}
        >
          <path
            fill="#fff"
            className={' fill-current ' + (isAddToCart === 'clicked' ? ' atc-top' : '')}
            d="M170,73.8H40.8l-4-20.4c-0.5-2.8-3-4.8-5.8-4.8H8.6c-3.2,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9h17.6
    l18.1,92.5l112.7,0c0,0,5,0.1,5.8-4.8c0,0,0,0,0,0l13.1-67.3C177.2,73.5,170,73.8,170,73.8z M152.2,141.1H53.9L43.1,85.5H163
    L152.2,141.1z"
          />

          <path
            fill="#fff"
            className={'atc-wheel fill-current '}
            d="M57.8,160.8l-2.6-13.4l-11,5.2l2,10c-6.3,3-10.7,9.5-10.7,17c0,10.4,8.4,18.8,18.8,18.8S73,189.9,73,179.6
    c0-2.5-0.5-4.9-1.4-7h62.5c-0.9,2.2-1.4,4.5-1.4,7c0,10.4,8.4,18.8,18.8,18.8c10.4,0,18.8-8.4,18.8-18.8c0-10.3-8.3-18.6-18.5-18.8
    L57.8,160.8z M54.2,186.6c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7s7,3.2,7,7C61.3,183.4,58.1,186.6,54.2,186.6z M151.6,186.6
    c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7c3.9,0,7,3.2,7,7C158.6,183.4,155.5,186.6,151.6,186.6z"
          />
          <path
            fill="#fff"
            className={' fill-current ' + (isAddToCart === 'clicked' ? ' atc-cargo' : ' opacity-0')}
            d="M152.2,141.1H53.9L43.1,85.5H163L152.2,141.1z"
          />
        </svg>
      </button>
      <button
        id="checkout"
        onClick={e => handleClicked(e, true)}
        className=" w-full cursor-pointer relative  justify-center overflow-hidden border-2 border-red-700 bg-red-700 text-center text-xl text-white md:mx-0"
      >
        <p className={isAddToCart === 'clicked' ? ' atc-text mr-14' : ' opacity-100 mr-14'}>
          BUY NOW
        </p>
        <div
          className={
            (isAddToCart === 'clicked' ? '  atc-bg' : ' ') +
            ' group  absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center'
          }
        >
          <p
            className={
              isAddToCart === 'clicked'
                ? ' fade-in absolute flex h-full w-full items-center justify-center  group-hover:bg-red-600'
                : ' opacity-0'
            }
          >
            BEGIN CHECKOUT
          </p>

          <p className={isAddToCart === 'clicked' ? ' cart-package' : ''}></p>

          <svg
            role={'img'}
            aria-label={'Add To Cart'}
            viewBox="0 0 200 200"
            className={
              ' absolute top-1 left-0 size-8 ' +
              (isAddToCart === 'clicked' ? '  cart-motion  ' : ' ') +
              (isAddToCart === 'out' ? ' -left-[60%] translate-x-0  duration-300' : '') +
              (isAddToCart === 'init' ? ' opacity-0 ' : ' ')
            }
          >
            <path
              fill="#fff"
              className={' fill-current ' + (isAddToCart === 'clicked' ? ' atc-top' : '')}
              d="M170,73.8H40.8l-4-20.4c-0.5-2.8-3-4.8-5.8-4.8H8.6c-3.2,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9h17.6
    l18.1,92.5l112.7,0c0,0,5,0.1,5.8-4.8c0,0,0,0,0,0l13.1-67.3C177.2,73.5,170,73.8,170,73.8z M152.2,141.1H53.9L43.1,85.5H163
    L152.2,141.1z"
            />

            <path
              fill="#fff"
              className={'atc-wheel fill-current '}
              d="M57.8,160.8l-2.6-13.4l-11,5.2l2,10c-6.3,3-10.7,9.5-10.7,17c0,10.4,8.4,18.8,18.8,18.8S73,189.9,73,179.6
    c0-2.5-0.5-4.9-1.4-7h62.5c-0.9,2.2-1.4,4.5-1.4,7c0,10.4,8.4,18.8,18.8,18.8c10.4,0,18.8-8.4,18.8-18.8c0-10.3-8.3-18.6-18.5-18.8
    L57.8,160.8z M54.2,186.6c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7s7,3.2,7,7C61.3,183.4,58.1,186.6,54.2,186.6z M151.6,186.6
    c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7c3.9,0,7,3.2,7,7C158.6,183.4,155.5,186.6,151.6,186.6z"
            />
            <path
              fill="#fff"
              className={
                ' fill-current ' + (isAddToCart === 'clicked' ? ' atc-cargo' : ' opacity-0')
              }
              d="M152.2,141.1H53.9L43.1,85.5H163L152.2,141.1z"
            />
          </svg>
        </div>
      </button>
      {/* <button
        id="checkout"
        onClick={handleClicked}
        className=" w-full cursor-pointer relative justify-center pr-14 overflow-hidden border-2 border-red-700 bg-red-700 text-center text-xl text-white md:mx-0"
      >
        {isInCart || isAddToCart === 'clicked' ? 'BEGIN CHECKOUT' : 'BUY NOW'}
      </button> */}
    </div>
    //   <button
    //     id="add-to-cart"
    //     onClick={handleClicked}
    //     className=" col-span-2 cursor-pointer relative  justify-center overflow-hidden rounded-md border-2 border-red-700 bg-red-700 py-3 text-center text-xl text-white md:mx-0"
    //     onMouseOver={() => setIsAddToCart(s => (s === 'clicked' ? s : 'hover'))}
    //     onMouseOut={() => setIsAddToCart(s => (s === 'clicked' ? s : 'out'))}
    //   >
    //     <p className={isAddToCart === 'clicked' ? ' atc-text' : ' opacity-100'}>BUY NOW</p>
    //     {/* <div
    //       className={
    //         (isAddToCart === 'clicked' ? '  atc-bg' : ' ') +
    //         ' group  absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center'
    //       }
    //     >
    //       <p
    //         className={
    //           isAddToCart === 'clicked'
    //             ? ' fade-in absolute flex h-full w-full items-center justify-center  group-hover:bg-red-600'
    //             : ' opacity-0'
    //         }
    //       >
    //         BEGIN CHECKOUT
    //       </p>

    //       <p className={isAddToCart === 'clicked' ? ' cart-package' : ''}></p>

    //       <svg
    //         role={'img'}
    //         aria-label={'Add To Cart'}
    //         viewBox="0 0 200 200"
    //         className={
    //           ' absolute top-0 h-full w-full py-2 ' +
    //           (isAddToCart === 'clicked' ? ' cart-motion left-0 translate-x-20' : '') +
    //           (isAddToCart === 'hover' ? ' -left-[60%] translate-x-20 duration-500' : '') +
    //           (isAddToCart === 'out' ? ' -left-[60%] translate-x-0  duration-300' : '') +
    //           (isAddToCart === 'init' ? ' opacity-0' : '')
    //         }
    //       >
    //         <path
    //           fill="#fff"
    //           className={' fill-current ' + (isAddToCart === 'clicked' ? ' atc-top' : '')}
    //           d="M170,73.8H40.8l-4-20.4c-0.5-2.8-3-4.8-5.8-4.8H8.6c-3.2,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9h17.6
    // l18.1,92.5l112.7,0c0,0,5,0.1,5.8-4.8c0,0,0,0,0,0l13.1-67.3C177.2,73.5,170,73.8,170,73.8z M152.2,141.1H53.9L43.1,85.5H163
    // L152.2,141.1z"
    //         />

    //         <path
    //           fill="#fff"
    //           className={'atc-wheel fill-current '}
    //           d="M57.8,160.8l-2.6-13.4l-11,5.2l2,10c-6.3,3-10.7,9.5-10.7,17c0,10.4,8.4,18.8,18.8,18.8S73,189.9,73,179.6
    // c0-2.5-0.5-4.9-1.4-7h62.5c-0.9,2.2-1.4,4.5-1.4,7c0,10.4,8.4,18.8,18.8,18.8c10.4,0,18.8-8.4,18.8-18.8c0-10.3-8.3-18.6-18.5-18.8
    // L57.8,160.8z M54.2,186.6c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7s7,3.2,7,7C61.3,183.4,58.1,186.6,54.2,186.6z M151.6,186.6
    // c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7c3.9,0,7,3.2,7,7C158.6,183.4,155.5,186.6,151.6,186.6z"
    //         />
    //         <path
    //           fill="#fff"
    //           className={' fill-current ' + (isAddToCart === 'clicked' ? ' atc-cargo' : ' opacity-0')}
    //           d="M152.2,141.1H53.9L43.1,85.5H163L152.2,141.1z"
    //         />
    //       </svg>
    //     </div> */}
    //     HELLO???
    //   </button>
  )
}

AddToCartBTN.propTypes = {
  onClick: PropTypes.func,
  isInCart: PropTypes.bool,
}

export default AddToCartBTN
