import * as React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'gatsby'
import { USD } from '../../helpers/NumberHelper'
import PropTypes from 'prop-types'
import finalPrice from '../../helpers/product/finalPrice'
import openChat from '../../state/popupForms'

const AskForAvailability = ({
  product,
  openAskAQuestion,
  openMakeAnOffer,
  isMakingAnOffer,
  openChatHandler,
}) => {
  return (
    <div className="mx-auto grid w-full max-w-[500px] grid-cols-2 gap-3 lg:mx-0 ">
      <div className="my-5 w-full rounded-lg border-2 border-red-600 p-4 text-center font-bold uppercase text-red-600 col-span-2">
        Ask for Availability
      </div>
      <p className="col-span-2">
        This item has been placed on HOLD. Please contact us for availability.
      </p>
      <button
        onClick={openChatHandler}
        className={
          'col-span-2 xxs:col-span-1 text-sm sm:text-base relative rounded-md border-2 border-green-700 py-3 font-bold text-green-700 duration-200 hover:bg-green-700 hover:text-white '
        }
      >
        CHAT
      </button>
      <button
        className="col-span-2 xxs:col-span-1 text-sm sm:text-base relative rounded-md border-2 border-gray-500 py-3 font-bold text-gray-500 duration-200 hover:bg-gray-500 hover:text-white"
        onClick={openAskAQuestion}
      >
        ASK A QUESTION
      </button>
    </div>
  )
}
AskForAvailability.propTypes = {
  product: PropTypes.object.isRequired,
}

export default AskForAvailability
