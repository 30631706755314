import * as React from 'react'
import { ProductImage } from '../CategoryImage'
import { USD } from '../../helpers/NumberHelper'
import ProductTitle from './ProductTitle'
import finalPrice from '../../helpers/product/finalPrice'
import isOnSale from '../../helpers/product/isOnSale'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const ProductStickyHeaderCard = ({
  product,
  category,
  show = false,
  addProductToCart,
  isInCart,
}) => {
  const handleClicked = React.useCallback(
    e => {
      if (isInCart) {
        navigate('/checkout/')
        return
      }
      addProductToCart(e)
    },
    [isInCart, addProductToCart]
  )
  const isSale = isOnSale(product)
  return (
    <div
      className={
        (show ? ' top-0 ' : ' top-[-12em] ') +
        'fixed duration-500 ease-out left-0 z-50 flex h-32 w-screen flex-row border-b border-gray-300 bg-white shadow-lg sm:hidden'
      }
    >
      <div className="aspect-square w-28 h-28 border-2 m-2">
        <ProductImage
          image={product.image}
          product={product}
          sizes={'(max-width: 768px) 100vw, 200px'}
          loading={'eager'}
          className="object-contain"
        />
      </div>
      <div className="w-full grid grid-rows-[1fr_2.5rem]  grid-cols-[1fr_auto] justify-start items-center">
        <div className="leading-none col-span-2 line-clamp-4 pr-2">
          <ProductTitle product={product} category={category} showRef={false} />
        </div>
        {/* This is the price for the mobile top bar component */}
        <p
          className={
            ' w-full mx-auto text-gray-700 text-xl text-left font-bold h-10 ' +
            (isSale ? ' text-red-700' : '')
          }
        >
          {USD(finalPrice(product))}
          <span
            className={
              'mx-2 max-w-max bg-red-700 px-2 py-1 text-sm font-bold text-white' +
              (isSale ? '' : ' hidden')
            }
          >
            SALE
          </span>
        </p>

        <button
          onClick={handleClicked}
          className=" border-2 border-red-700 h-10 flex flex-row flex-wrap overflow-hidden justify-center items-center gap-1 text-sm px-3 text-red-700 shadow-lg leading-10 "
        >
          <div className="flex items-center justify-center text-right h-10">
            <svg
              role={'img'}
              aria-label={'Add To Cart'}
              viewBox="0 0 200 200"
              className={'w-6 -translate-y-1 '}
            >
              <path
                fill="#fff"
                className={' fill-current '}
                d="M170,73.8H40.8l-4-20.4c-0.5-2.8-3-4.8-5.8-4.8H8.6c-3.2,0-5.9,2.6-5.9,5.9c0,3.2,2.6,5.9,5.9,5.9h17.6
	l18.1,92.5l112.7,0c0,0,5,0.1,5.8-4.8c0,0,0,0,0,0l13.1-67.3C177.2,73.5,170,73.8,170,73.8z M152.2,141.1H53.9L43.1,85.5H163
	L152.2,141.1z"
              />

              <path
                fill="#fff"
                className={'atc-wheel fill-current '}
                d="M57.8,160.8l-2.6-13.4l-11,5.2l2,10c-6.3,3-10.7,9.5-10.7,17c0,10.4,8.4,18.8,18.8,18.8S73,189.9,73,179.6
	c0-2.5-0.5-4.9-1.4-7h62.5c-0.9,2.2-1.4,4.5-1.4,7c0,10.4,8.4,18.8,18.8,18.8c10.4,0,18.8-8.4,18.8-18.8c0-10.3-8.3-18.6-18.5-18.8
	L57.8,160.8z M54.2,186.6c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7s7,3.2,7,7C61.3,183.4,58.1,186.6,54.2,186.6z M151.6,186.6
	c-3.9,0-7-3.2-7-7c0-3.9,3.2-7,7-7c3.9,0,7,3.2,7,7C158.6,183.4,155.5,186.6,151.6,186.6z"
              />
              <path
                fill="#fff"
                className={' fill-current '}
                d="M152.2,141.1H53.9L43.1,85.5H163L152.2,141.1z"
              />
            </svg>
          </div>
          <p>BUY</p>
          <p>NOW</p>
        </button>
      </div>
    </div>
  )
}
ProductStickyHeaderCard.propTypes = {
  product: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  show: PropTypes.bool,
  addProductToCart: PropTypes.func,
  isInCart: PropTypes.bool,
}

export default ProductStickyHeaderCard
