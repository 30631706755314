import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import isBankWireOnly from '../helpers/product/isBankWireOnly'
import { showPopup, openChat } from '../state/popupForms'
import EmailLink from './EmailLink'
import PhoneLink from './PhoneLink'

const Button = ({ text, trigger }) => {
  return (
    <button
      onClick={trigger}
      className=" uppercase w-full rounded-md bg-gray-500 py-2 text-white hover:bg-red-700 px-5"
    >
      {text}
    </button>
  )
}

const ContactSideCard = ({ image, details, children }) => {
  return (
    <div className="@container/card flex-wrap group w-full md:max-w-[400px] lg:max-w-[500px] m-5 flex flex-row items-center justify-center rounded-md border border-gray-200 bg-gradient-to-b from-white via-gray-100 to-gray-100 p-5 text-center shadow-md duration-150 hover:border-gray-400 md:flex-col lg:m-2 lg:flex-row 2xl:w-[300px] 2xl:flex-col">
      <div className="card flex flex-col @sm:flex-row justify-center items-center">
        <div className=" aspect-square w-[200px] rounded-full border border-gray-200 duration-150 group-hover:border-gray-300 overflow-hidden">
          {image}
        </div>
        <div className="w-full my-2">{details}</div>
      </div>
      <div className="flex flex-row gap-4 w-full justify-center">{children}</div>
    </div>
  )
}

const ViktoriaSideCard = () => {
  const dispatch = useDispatch()
  const openChatHandler = React.useCallback(e => {
    e.preventDefault()
    dispatch(openChat())
  }, [])
  return (
    <ContactSideCard
      image={
        <StaticImage
          alt="Livechat with Viktoria image"
          src={'../images/viktoria-livechat.jpeg'}
          loading="eager"
          className=""
        />
      }
      details={
        <>
          <p>
            <span className="text-xl font-bold">VIKTORIA</span>
            <br />
            I am a decision maker <br /> Ready to make a deal Now!
          </p>
          <PhoneLink className="my-4 block text-red-600 hover:text-red-700" phone="(786) 266-4763">
            <b>(786) 266-4763</b>
            <nobr>&nbsp;(Text or Call)</nobr>
          </PhoneLink>
        </>
      }
    >
      <button
        onClick={openChatHandler}
        className="uppercase w-full rounded-md bg-gray-500 py-2 text-white hover:bg-red-700 px-8 max-w-max"
      >
        Chat
      </button>
      <EmailLink email="viktoria@grayandsons.com"
        className="uppercase w-full rounded-md bg-gray-500 py-2 text-white hover:bg-red-700 px-8 max-w-max"
      >
        Email
      </EmailLink>
    </ContactSideCard>
  )
}

const KeithSideCard = () => {
  return (
    <ContactSideCard
      image={
        <>
          <StaticImage
            alt="Make a deal with Keith Gray"
            src={'../images/keith-gray-profile.jpg'}
            loading="eager"
            className=""
          />
        </>
      }
      details={
        <>
          <i className="active-btn font-bold text-red-600 relative w-full pr-3">AVAILABLE NOW</i>
          <p className="">
            <span className="text-xl font-bold">KEITH GRAY</span>
            <br /> PRESIDENT of GRAY AND SONS
            <br /> Ready to make a deal Now!
          </p>
          <PhoneLink
            className="my-4 block bg-red-600 hover:bg-red-700 duration-200 text-white font-bold py-2 rounded-lg"
            phone="(305) 209-0545"
          >
            CALL OR TEXT <br />
            (305) 209-0545
          </PhoneLink>
          Only Today, <b>Sunday, April 28th</b> <br />
          from 12:00PM to 4:00PM EST.
        </>
      }
    ></ContactSideCard>
  )
}

const RichSideCard = ({ product }) => {
  const dispatch = useDispatch()
  const openAskAQuestion = useCallback(() => {
    dispatch(showPopup({ product, makeAnOffer: false }))
  }, [])
  const openMakeAnOffer = useCallback(() => {
    dispatch(showPopup({ product, makeAnOffer: true }))
  }, [])

  return (
    <ContactSideCard
      image={
        <StaticImage
          alt="Livechat with Viktoria image"
          src={'../images/rich-make-an-offer.jpeg'}
          loading="eager"
          className=""
        />
      }
      details={
        <>
          <p>
            <span className="text-xl font-bold">RICH</span>
            <br />
            I am a decision maker <br /> Ready to make a deal Now!
          </p>
          <PhoneLink className="my-4 block text-red-600 hover:text-red-700">
            <b>(305) 865 0999</b>
          </PhoneLink>
        </>
      }
    >
      {isBankWireOnly(product) || product.in_stock === false ? (
        <>
          {product && (
            <button
              onClick={openAskAQuestion}
              className="uppercase w-full rounded-md bg-gray-500 py-2 text-white hover:bg-green-600 px-8 max-w-max"
            >
              Ask a Question
            </button>
          )}
        </>
      ) : (
        <>
          {product && (
            <button
              onClick={openMakeAnOffer}
              className="uppercase w-full rounded-md bg-gray-500 py-2 text-white hover:bg-green-600 px-8 max-w-max"
            >
              Make an offer
            </button>
          )}
        </>
      )}
    </ContactSideCard>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  trigger: PropTypes.func,
}
ContactSideCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  image: PropTypes.node.isRequired,
  details: PropTypes.node.isRequired,
}
RichSideCard.propTypes = {
  product: PropTypes.object,
}

export { ViktoriaSideCard, RichSideCard, KeithSideCard }
