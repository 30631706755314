import * as React from 'react'
import { Link } from 'gatsby'
import { USD } from '../../helpers/NumberHelper'
import PropTypes from 'prop-types'
import finalPrice from '../../helpers/product/finalPrice'
import isOnSale from '../../helpers/product/isOnSale'
import EstimatedTotalPrice from './EstimatedTotalPrice'
import { MAXIMUM_AFFIRM_AMOUNT, MINIMUM_AFFIRM_AMOUNT } from '../../api-common/payments/affirm'

const PriceCardContent = ({ product }) => {
  const final = finalPrice(product)
  const hasRetailPrice =
    !!product.retail_price && product.retail_price > 0 && product.retail_price > final
  const isSale = isOnSale(product)

  return (
    <div className="md:min-w-max flex flex-col">
      <p className={hasRetailPrice ? ' order-1' : ' hidden'}>
        Est. Retail Price {USD(product.retail_price)}
      </p>

      <div className="order-2 flex text-sm">
        {isSale && (
          <div className="block ">
            <b>
              G<span className="text-red-700">&amp;</span>S
            </b>{' '}
            Price: <span className="line-through decoration-red-600">{USD(product.price)} </span>
            {/* Original Product Price - Strike Through */}
          </div>
        )}
        <p className={hasRetailPrice && !isSale ? '' : ' hidden'}>
          Save: {USD(product.retail_price - final)}
        </p>
        <p className={hasRetailPrice ? ' ' : ' hidden'}>
          &ensp;/ {Math.round(100 * (1 - final / product.retail_price))}% Off Retail
        </p>
      </div>
      <p
        className={
          ' space-x-1 text-3xl font-bold' +
          (isSale ? ' text-red-700 order-4' : '') +
          (hasRetailPrice ? ' order-1' : ' text-5xl')
        }
      >
        {USD(final)} &ensp;
        <span
          className={
            'max-w-max bg-red-700 px-3 text-lg font-bold text-white' +
            (isSale ? ' order-5 ' : ' hidden') +
            (hasRetailPrice ? ' inline-block -translate-y-1' : '  block')
          }
        >
          SALE
        </span>
      </p>
    </div>
  )
}

const ProductPrice = ({ product, affirmPrice, hasAffirm, onAffirmDetailsClick }) => {
  return (
    <div className="max-w-max mx-auto md:mx-0">
      <div className="my-5 mx-auto flex flex-col items-center leading-loose text-gray-700 xs:flex-row md:mx-0 md:flex-col md:items-start md:justify-center lg:flex-row lg:items-center lg:justify-start">
        <PriceCardContent product={product} />
        {hasAffirm && (
          <div className={'flex flex-row items-center justify-start leading-normal md:min-w-max'}>
            <p className="hidden translate-x-3 bg-white text-gray-700 xs:flex md:hidden lg:flex ">
              OR
            </p>
            <p className="border-gray-600 xs:border-l-2 xs:pl-8 md:border-0 md:pl-0 lg:border-l-2 lg:pl-8 ">
              Financing with Affirm: &ensp;
              <b className="inline whitespace-nowrap text-xl leading-none xs:block sm:block md:inline lg:block">
                {USD(affirmPrice)} / month
              </b>
              <span className="hidden lg:block">over 36 months at 15% APR</span>
              <button
                onClick={onAffirmDetailsClick}
                className="block w-full max-w-max border-b border-gray-600 leading-none hover:border-red-700 hover:text-red-700"
              >
                See Details
              </button>
            </p>
          </div>
        )}
      </div>
      <EstimatedTotalPrice products={[product]} summary={false} nonUs={false} />
    </div>
  )
}
ProductPrice.propTypes = {
  product: PropTypes.object.isRequired,
  affirmPrice: PropTypes.number,
  hasAffirm: PropTypes.bool,
  isSale: PropTypes.bool,
}

// const AskForAvailability = ({ product }) => {
//   return (
//     <div className="space-y-2">
//       <div className="my-5 w-full max-w-[500px] rounded-lg border-2 border-gray-300 p-4 text-center font-bold uppercase text-gray-500">
//         currently unavailable
//       </div>
//       Sold <a title={`${product.stock_date}`}>{product.ago}</a>. Asking price{' '}
//       <b>{USD(finalPrice(product))}</b>
//       <p>
//         Do you own this or a similar item and would like to <b>sell to us?</b>
//       </p>
//       {/* eslint-disable-next-line */}
//       <a
//         href={
//           'https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/?utm_source=referral&utm_medium=grayandsons&utm_campaign=we_buy'
//         }
//         rel="noopener"
//         target={'_blank'}
//         className="w-full block text-center max-w-[500px] rounded-lg bg-red-700 py-4 font-bold text-white hover:bg-red-800"
//       >
//         SELL ONE LIKE THIS
//       </a>
//     </div>
//   )
// }
// AskForAvailability.propTypes = {
//   product: PropTypes.object.isRequired,
// }

const calculateAffirm = principal => {
  const apr = 0.15
  const termInMonths = 36
  const monthlyInterestRate = apr / 12

  const monthlyPayment =
    (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, termInMonths)) /
    (Math.pow(1 + monthlyInterestRate, termInMonths) - 1)

  return monthlyPayment
}

const PriceCard = ({ product, showAffirm = true, onAffirmDetailsClick }) => {
  const final = finalPrice(product)
  const affirmPrice = calculateAffirm(final)
  const isSale = isOnSale(product)
  const hasAffirm =
    showAffirm && product.sku && MINIMUM_AFFIRM_AMOUNT <= final && final <= MAXIMUM_AFFIRM_AMOUNT
  return (
    <ProductPrice
      product={product}
      affirmPrice={affirmPrice}
      hasAffirm={hasAffirm}
      isSale={isSale}
      onAffirmDetailsClick={onAffirmDetailsClick}
    />
  )
}

PriceCardContent.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.number,
    special_price: PropTypes.number,
    retail_price: PropTypes.number,
  }).isRequired,
}
PriceCard.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.string,
    price: PropTypes.number,
    special_price: PropTypes.number,
    retail_price: PropTypes.number,
  }).isRequired,
  showAffirm: PropTypes.bool,
}

export { PriceCardContent }
export default PriceCard
