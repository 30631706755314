import React, { useState, useEffect } from 'react'
import { IconShippingTruck } from '../Icons'
import PropTypes from 'prop-types'

function getNextDeliveryDate(date) {
  // Check for weekends
  if (date.getDay() === 1) {
    // Monday
    date.setDate(date.getDate() + 1) // Move to Tuesday
  } else if (date.getDay() === 6) {
    // Saturday
    date.setDate(date.getDate() + 3) // Move to Tuesday
  } else if (date.getDay() === 0) {
    // Sunday
    date.setDate(date.getDate() + 2) // Move to Tuesday
  }

  // Check for US holidays
  const holidays = [
    '1-1', // New Year's Day
    '7-4', // Independence Day
    '12-25', // Christmas Day
    // Thanksgiving Day - 4th Thursday of November
  ]
  const monthDay = `${date.getMonth() + 1}-${date.getDate()}`
  if (holidays.includes(monthDay)) {
    date.setDate(date.getDate() + 1) // Move to next day
    // and check if it's a weekend
    return getNextDeliveryDate(date)
  }
  // Special handling for Thanksgiving (4th Thursday of November)
  if (isThanksgiving(date)) {
    date.setDate(date.getDate() + 5) // move to Tuesday
  }
  return date
}

function isThanksgiving(date) {
  return date.getMonth() === 10 && date.getDay() === 4 && Math.floor((date.getDate() - 1) / 7) === 3
}

function calculateDeliveryDate(delta = 1) {
  // Create a Date object for the current time
  const now = new Date()
  const estOffset = 5 * 60 // EST offset in minutes
  const localOffset = now.getTimezoneOffset() // Local time offset in minutes
  const estTime = new Date(now.getTime() + (estOffset - localOffset) * 60000)
  const isBefore11AM = estTime.getHours() < 12
  const adjustedDays = isBefore11AM ? delta : delta + 1
  let shippingDay = new Date() // Current date
  shippingDay.setDate(shippingDay.getDate() + adjustedDays) // Add one day for overnight shipping
  shippingDay = getNextDeliveryDate(shippingDay)
  return shippingDay
}

const EstimatedDeliveryDate = ({ className }) => {
  const [deliveryRange, setDeliveryRange] = useState({ start: '', end: '' })

  useEffect(() => {
    const startDate = calculateDeliveryDate(1)
    const endDate = calculateDeliveryDate(4)
    setDeliveryRange({
      start: formatDate(startDate),
      end: formatDate(endDate),
    })
  }, [])

  const formatDate = date => {
    return date.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' })
  }

  return (
    <div className={className}>
      <div>
        <IconShippingTruck className={'w-7 h-7 hidden md:block'} />
      </div>
      <span>
        Est. <span className="hidden xxs:inline">Delivery</span>{' '}
        <span className="hidden lg:inline">Between</span>:
      </span>
      {deliveryRange?.start && deliveryRange?.end ? (
        <>
          <b>{deliveryRange.start}</b> and <b>{deliveryRange.end}</b>
        </>
      ) : (
        <p className="text-right inline-block skeleton h-4 bg-gray-200/80 rounded-md overflow-hidden max-w-max text-transparent relative translate-y-[.15rem]">
          loading...
        </p>
      )}
    </div>
  )
}
EstimatedDeliveryDate.propTypes = {
  className: PropTypes.string,
}

// Functions from previous snippet (getNextWorkingDay, isThanksgiving, isChristmas, calculateDeliveryDate)

export default EstimatedDeliveryDate
