import * as React from 'react'
import { Link } from 'gatsby'
import { IconSizeGuidePDF } from '../Icons'
import PropTypes from 'prop-types'
import { sizeGuideByCategory } from '../../../utils/sizeGuide'

const FindYourSize = ({ category, link, onClick }) => {
  // take url as a fallback, it should fetch link from
  // graphql - the canonical path for the page if any changes
  // were applied
  const { url, pdf } = sizeGuideByCategory(category)
  const onClickHandler = React.useCallback(
    e => {
      e.preventDefault()
      onClick(pdf)
    },
    [onClick, pdf]
  )
  if (!pdf) return null
  return (
    <section className="md:col-span-4 col-span-2 py-2 gap-2 flex flex-row items-end">
      {/* eslint-disable-next-line react/jsx-no-target-blank */ }
      <a 
        href={pdf} 
        target={'_blank'} 
        rel="noopener"
        className=" hover:text-red-600 "
        onClick={onClickHandler}
      >
        <IconSizeGuidePDF />
      </a>
      <Link
        to={link || url}
        className=" w-full hover:text-red-600 uppercase "
        onClick={onClickHandler}
      >
        {category} SIZE GUIDE
      </Link>
    </section>
  )
}
FindYourSize.propTypes = {
  category: PropTypes.string,
  onClick: PropTypes.func,
  link: PropTypes.string,
}

export default FindYourSize
